import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allanalyz: [],
};
const allAnalaytics = createSlice({
  name: "allanalyz",
  initialState,
  reducers: {
    addAnalaytic: (state, action) => {
      state.allanalyz = action.payload;
    },
  },
});

export default allAnalaytics.reducer;
export const { addAnalaytic } = allAnalaytics.actions;