import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
  en: {
    translation: {
      "Welcome to LeaLap": "Welcome to LeaLap",
      "Last Pateint": "Last Pateint",
    },
  },
  ar: {
    translation: {
      "Edit Specialization": "تعديل التخصص",
      "Delete Specialization": "حذف التخصص",
      "Do you want to delete this Specialization?": "هل تريد حذف هذا التخصص؟",
      "Delete Job Title": "حذف عنوان العمل",
      "Do you want to delete this Job Title?": "هل تريد حذف عنوان العمل هذا ؟",
      "Edit Job Title": "تعدبل عنوان العمل",
      Specialization: "تخصصات",
      "Job Title": "عنوان العمل",
      Home: "الرئيسية",
      Analytic: "تحاليل",
      exports: "صادرات",
      "Amounts analysis": "مبالغ التحليل",
      Labs: "مخابر",
      Staff: "موظفين",
      Store: "مخزن",
      Reports: "تقارير",
      Accounting: "حسابات",
      Suppliers: "الموردين",
      Settings: "الإعدادات",
      Hi: "مرحبا",
      Logout: "تسجيل الخروج",
      Action: "تعديل",
      Email: "البريد الالكتروني",
      "Ratio price": "النسبة",
      "Sent lab": "المخبر المرسل",
      "Lap id": "رقم المخبر",
      Add: "أضف",
      "Patient address": "عنوان المريض",
      "Receive of data": "تاريخ الاستقبال",
      "Patient name": "اسم المريض",
      "Date of visit": "تاريخ الزيارة",
      "Date of Visit": "تاريخ الزيارة",
      "Phone number": "رقم الهاتف",
      Gender: "الجنس",
      "Measurement unit name": "اسم وحدة القياس",
      "Edit Gender": "تعديل الجنس",
      Age: "العمر",
      Doctor: "طبيب",
      "Doctor's amount": "مبلغ الطبيب",
      "Dr.Ratio": "نسبة الطبيب",
      Price: "السعر",
      "We are glad you are joining us. Good day!":
        "نحن مسرورين بإنضمامك إلينا. يوم جيد!",
      "Welcome to LeaLap": "مرحبا بك في مخبر ليا",
      "Last Pateint": "آخر المرضى",
      "Doctors section": "قسم الأطباء",
      "Reviewers Section": "قسم المراجعين",
      "Current Year": "السنة الحالية",
      "Delete Intrputik": "حذف المضاد الحيوي",
      "Do you want to delete this Intrputik?": "هل تريد حذف مضاد الحيوي هذا؟",
      Range: "المعدل",
      "Do you want to delete this Range?": "هل تريد حذف هذا المعدل؟",
      "Delete Range": "حذف المعدل",
      "Edit Range": "تعديل المعدل",
      "Report Text": "نص التقرير",
      "Edit Report": "تعديل تقرير",
      "Do you want to delete this Report?": "هل تريد حذف هذا التقرير؟",
      Report: "تقرير",
      "Delete Report": "حذف التقرير",
      "Last Year": "السنة الماضية",
      "Store Lab": "مخزن المتجر",
      "Scientific offices": "مكاتب علمية",
      "Scientific office": "مكتب علمي",
      "Add Bill": "أضف فاتورة",
      Products: "المنتجات",
      Pay: "ادفع",
      "Pay the bill": "دفع الفاتورة",
      "Amount in dinars": "المبلغ بالدينار",
      "Amount in dollars": "المبلغ بالدولار",
      "Edit Supplier": "تعديل المورد",
      "Do you want to delete This Supplier?": "هل تريد حذف هذا المورد؟",
      "Delete Supplier": "حذف مورد",
      "Edit Office Bill": "تعديل فاتورة مكتب",
      "Delete Office Bill": "حذف فاتورة المكتب",
      "Do you want to delete This Bill?": "هل تريد حذف هذه الفاتورة",
      "Total Payment": "الدفعة الاجمالية",
      "Total payment": "الدفعة الاجمالية",
      "Edit Office Bills": "تعديل فواتير المكتب",
      "Date of purchase": "تاريخ الشراء",
      "Date of Purchase": "تاريخ الشراء",
      "Print Settings": "إعدادات الطباعة",
      Premissions: "صلاحيات",
      "Basic plugins": "الإضافات الأساسية",
      "Gender name": "اسم الجنس",
      "Units store name": "اسم مخزن الوحدات",
      "Measurement Units": "وحدات القياس",
      "Measurement unit": "وحدة قياس",
      "Scientific disciplines": "التخصصات العلمية",
      "Unit Store": "مخزن الوحدة",
      "Do you want to delete this Test Method?":
        "هل تريد حذف طريقة الإختبار هذه؟",
      "Edit Test Method": "تعديل طريقة الإختبار",
      "Delete Unit Store": "حذف مخزن الوحدة",
      "Edit Unit Store": "تعديل مخزن الوحدة",
      "Do you want to delete this Unit Store?": "هل تريد حذف مخزن الوحدة هذا؟",
      "Test method": "طريقة الإختبار",
      "Test Method": "طريقة الإختبار",
      "Edit Unit": "تعديل الوحدة",
      "Do you want to delete this Unit?": "هل تريد حذف هذه الوحدة؟",
      "Delete Measuring unit": "حذف وحدة القياس",
      Unit: "الوحدة",
      "Do you want to delete this Gender?": "هل تريد حذف هذا الجنس؟",
      "Delete Gender": "حذف الجنس",
      "Units store": "مخزن الوحدات",
      "System Owner": "ًصاحب النظام",
      Settings: "الإعدادات",
      "Currencies name": "اسم العملات",
      "English Fonts": "الخطوط للإنكليزية",
      "Arabic Fonts": "الخطوط للعربية",
      "Edit Currnecy": "تعديل العملة",
      "Mark all as read": "حدد الجميع كمقروء",
      Currnecy: "العملة",
      Currency: "العملة",
      "Edit Currency": "تعديل العملة",
      "Do you want to delete this Currnecy?": "هل تريد حذف هذه العملة؟",
      "Delete Currnecy": "حذف العملة",
      "Add To the Table": "أضف إلى الجدول",
      Setting: "الإعدادات",
      Currencies: "العملات",
      "Font used Printing": "الخط المستخدم في الطباعة",
      "System Settings": "إعدادات النظام",
      "Added date": "تاريخ الاضافة",
      "Added Date": "تاريخ الاضافة",
      "Maintenance engineer Mobile": "رفم مهندس الصيانة",
      "Scientific office name": "اسم المكتب العلمي",
      "Scientific office names": "أسماء المكاتب العلمية",
      "Scientific office bills": "فواتير المكاتب العلمية",
      "Paid bills": "الفواتير المدفوعة",
      "Analysis name": "اسم التحليل",
      "Price for lab": "السعر للمخبر",
      "Amounts (doctor's share)": "المبالغ (حصة الطبيب)",
      "Amount (doctor's share)": "المبلغ (حصة الطبيب)",
      "Price for company": "السعر للشركة",
      "Price for patient": "السعر للمريض",
      Categories: "المجموعات",
      "Examination available in system": "الفحص متوفر في النظام",
      Prices: "الأسعار",
      Examinations: "الفحوصات",
      "Send lab": "ارسال الى المخبر",
      "Receive lab": "استقبال من المخبر",
      "Amount (ID)": "معرف المبلغ",
      "Name (Patient or lab or company)": "اسم (المريض أو المخبر أو الشركة)",
      Debt: "دين",
      "Referring doctors": "إحالات الأطباء",
      "Number of reviewers": "عدد المراجعين",
      "Expense for the day": "النفقات لليوم",
      "Amount paid": "المبلغ المدفوع",
      "The amount paid": "المبلغ المدفوع",
      "Total amount bill ID": "إجمالي معرّف فاتورة المبلغ",
      "Total amount bill $": "إجمالي مبلغ الفاتورة $",
      "Delete Export Payment": "حذف الدفعة الصادرة",
      "Delete Revenue Payment": "حذف الدفعة الواردة",
      Bill: "فاتورةءءء",
      Payment: "الدفعة",
      "Do you want to delete this Payment Method?":
        "هل تريد حذف طريقة الدفع هذه؟",
      "Do you want to delete this Payment?": "هل تريد حذف هذه الدفعة؟",
      "Total amount": "المبلغ الاجمالي",
      "Add Paid Bill": "أضف فاتورة مدفوعة",
      "Amount $ before payment": "المبلغ $ قبل الدفع",
      "Amount ID before payment": "معرف المبلغ قبل الدفع",
      "Amount $ after payment": "المبلغ $ بعد الدفع",
      "Amount ID after payment": "المبلغ المعرف بعد الدفع",
      "Invoice number": "رقم الفاتورة",
      "Invoice Number": "رقم الفاتورة",
      "Office name": "اسم المكتب",
      "Add Supplier": "أضف مورد",
      "Remaining for today": "المتبقي لليوم",
      "Doctor ratio for the day": "نسبة الطبيب لليوم",
      "Doctor's ratio for the day": "نسبة الطبيب لليوم",
      "Total amount for the day": "المبلغ الكامل لليوم",
      "Total amount ID": "معرف المبلغ الاجمالي",
      "Total amount $": "المبلغ الاجمالي $",
      Offices: "المكاتب",
      "Exchange employees": "موظفي الصرف",
      "Exchange parties": "حزم الصرف",
      "Amount spent": "المبلغ المدفوع",
      "Exchange party": "حزم الصرف",
      "Exchange employee": "موظف الصرف",
      "Exchange check number": "رقم الصرف",
      "Remaining amounts": "المبالغ المتبقية",
      "Local laboratories": "مخابر محلية",
      Checkups: "الفحوصات",
      "Remaining amount": "المبلغ المتبقي",
      "Amount received": "المبلغ المستلم",
      "Local laboratory": "مخبر محلي",
      "Referring doctor": "إحالة الطبيب",
      "Dr. Ratio": "نسبة الطبيب",
      "Doctor's name": "اسم الطبيب",
      "Collected money": "النقود المجموعة",
      Doctors: "الأطباء",
      Amounts: "مبالغ",
      Workers: "العمال",
      Analysis: "التحليلات",
      "Payment Method": "طريقة الدفع",
      Patients: "المرضى",
      "o you want to delete this Payment Method?":
        "هل تريد دفع طريقة الدفع هذه؟",
      "Edit Payment Method": "تعديل طريقة الدفع",
      "Amount analysis": "مبلغ التحليل",
      "Delete Payment Method": "حذف طريقة الدفع",
      "Doctor amounts": "مبالغ الطبيب",
      "Company name": "اسم الشركة",
      "Do you want to delete this Company?": "هل تريد حذف هذه الشركة؟",
      Company: "الشركة",
      "Edit Company": "تعديل الشركة",
      "Analysis today": "تحليلات اليوم",
      "View all": "عرض الكل",
      "Delete Company": "حذف الشركة",
      Notifications: "التبيهات",
      "Show all notifications": "عرض كل التنبيهات",
      "Reviewers Section": "قسم المراجعين",
      "Material name": "اسم المادة",
      "Find the names of the reviewers here": "جد أسماء المراجعين هنا",
      "Send method": "طريقة الارسال",
      "Send Method": "طريقة الارسال",
      "Do you want to delete this Send Method?":
        "هل تريد حذف طريقة الارسال هذه؟",
      "Payment methods": "طرق الدفع",
      "Send methods": "طرق الارسال",
      "Edit Send Method": "تعديل طريقة الارسال",
      "Delete Send Method": "حذف طريقة الارسال",
      "Single Print": "طباعة واحدة",
      Once: "واحدة",
      Names: "الأسماء",
      Quantity: " الكمية",
      Urgency: "عاجل",
      Company: "الشركة",
      Discount: "تخفيض",
      Print: "طباعة",
      "Product name": "اسم المنتج",
      "Product Name": "اسم المنتج",
      Description: "وصف",
      "Add Product": "أضف منتج",
      "Delete Product": "حذف المنتج",
      "Do you want to delete this product?": "هل تريد حذف هذا المنتج",
      product: "منتج",
      "Delete outSide Product": "حذف منتج مخرج",
      "Print barcode": "باركود الطباعة",
      "Print for patient": "طباعة للمريض",
      "Print for lab": "طباعة للمخبر",
      "Analysis price": "سعر التحليل",
      "Print for drawing room": "طباعة لغرفة الرسم",
      "Do you want to delete this patient?": "هل تريد حذف هذا المريض",
      "Delete Patient": "حذف المريض",
      Yes: "نعم",
      No: "لا",
      "Edit patient": "تعديل المريض",
      Cancel: "إلغاء",
      "Payment type": "نوع الدفع",
      "Payment amount": "المبلغ المدفوع",
      Exports: "الصادرات",
      Payment: "الدفعة",
      "Paid up": "دفع",
      "Analysis type": "نوع التحليل",
      "Analysis type child": "التحليل الثانوي",
      Notes: "ملاحظات",
      Note: "ملاحظة",
      Payment_amount: "المبلغ المدفوع",
      "Non emergency": "غير عاجل",
      Emergency: "عاجل",
      EXP: "الصلاحية",
      Exp: "الصلاحية",
      "Out side date": "تاريخ الاخراج",
      "Edit Product": "تعديل المنتج",
      "Edit outSide Product": "تعديل منتج مخرج",
      Model: "النوع",
      CompanyID: "رمز الشركة",
      "Sort by": "صنف حسب",
      All: "الكل",
      "Not complete": "غير مكتمل",
      "Completed and not printed": "مكتمل و غير مطبوع",
      Printed: "مطبوع",
      Laboratories: "مخابر",
      "Add patient": "أضف مريض",
      From: "من",
      To: "إلى",
      "Add section": "اضافة قسم",
      "Move up": "أعلى",
      "Move down": "أسفل",
      Save: "حفظ",
      Delete: "حذف",
      intrputik: "مضادات حيوية",
      "Edit Intrputik": "تعديل المضاد الحيوي",
      "Move up": "إلى الأعلى",
      "Move down": "إلى الأسفل",
      "Intrputik name": "اسم المضاد الحيوي",
      "Section name": "اسم القسم",
      "Test code": "الرمز",
      Category: "المجموعة",
      "Examination name": "اسم التحليل",
      "Test name in print": "اسم التحليل في الطبع",
      "Test method": "طريقة الاختبار",
      "Type of Tupe": "نوع الأنبوب",
      "Test price": "سعر التحليل",
      "Laboratory Test price": "سعر تحليل المخبر",
      "Test price for companies": " سعر التحليل للشركات",
      Companies: "الشركات",
      "Test unit": "وحدة قياس",
      "Measuring unit": "وحدة قياس",
      "Out Product": "إخراج منتج",
      Reviewers: "المراجعين",
      Revenues: "الإيرادات",
      Expenses: "نفقات",
      Date: "التاريخ",
      "Analysis count": "عدد التحاليل",
      "out side date": "تاريخ الاخراج",
      "Add to table": "أضف الى الجدول",
      "Intrputik name": "اسم المضاد الحيوي",
      "Class analysis": " نوع التحليل",
      name: "الاسم",
      Name: "الاسم",
      Worktime: "وقت العمل",
      Specialization: "الاختصاص",
      "Salary rate": "نسبة من الراتب",
      College: "التعليم",
      Experience: "الخبرة",
      "Type of result": "النتيجة",
      "Normal range": "المعدل الطبيعي",
      H: "الأعلى",
      L: "الأدنى",
      "Add normal": "أضف المعدل الطبيعي",
      "subject title": "الموضوع",
      "First entry box": "الموضوع",
      Day: "يوم",
      Week: "أسبوع",
      Month: "شهر",
      "Add Doctor": "أضف طبيب",
      "Delete Doctor": "حذف طبيب",
      "Do you want to delete this Doctor?": "هل تريد حذف هذا الطبيب",
      "Add doctor": "أضف طبيب",
      Amount: "المبلغ",
      Reset: "إعادة ضبط",
      Apply: "تطبيق",
      "Doctor name": "اسم الطبيب",
      "Doctor's address": "عنوان الطبيب",
      "Doctor ratio": "نسبة الدكتور",
      "Doctor's Ratio": "نسبة الدكتور",
      "Add doctor": "أضف دكتور",
      "Drop your here, or": "",
      "select click to browse": "إختر الضغط للاختيار",
      "click to browse": "أضغظ لتغيير الصورة",
      "Inside store": "تاريخ التخزين",
      "Out date": "تاريخ الخروج",
      name: "الاسم",
      "Doctor Address": "عنوان الطبيب",
      "Doctor address": "عنوان الطبيب",
      Password: "كلمة المرور",
      "Edit doctor": "عدل الطبيب",
      Female: "أنثى",
      Male: "ذكر",
      Add: "أضف",

      "Add Lab": "أضف مخبر",
      "Lab name": "اسم المخبر",
      Address: "العنوان",
      Code: "الرمز",
      Edit: "تعديل",
      "Date of birth": "تاريخ الولادة",
      "Work start": "يبدأ العمل",
      "View profile": "عرض الموظف",
      "Edit Profile": "تعديل الصفحة الشخصية",
      "Add worker": "أضف موظف",
      "Add employee": "أضف موظف",
      Inside: "داخل",
      OutSide: "خارج",

      "Employee name": "اسم الموظف",
      "Job title": "عنوان العمل",
      "Delete Employee": "حذف الموظف",
      "Do you want to delete this empolyee?": "هل تريد حذف هذا الموظف",
      empolyee: "موظف",
      "With system": "في النظام",
      "Without system": "خارج النظام",
      "Delete Lab": "حذف المخبر",
      "Do you want to delete this Lab?": "هل تريد حذف هذا المخبر",
      Lab: "مخبر",
      Analytics: "التحاليل",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
